import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      version="1.1"
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path
        d="M14.784 11.643a5.352 5.352 0 00-5.35 5.35 5.352 5.352 0 005.35 5.349 5.352 5.352 0 005.35-5.35 5.352 5.352 0 00-5.35-5.35zm-4.28 5.35c0-.327.043-.648.112-.953l2.557 2.557v.535c0 .589.482 1.07 1.07 1.07v1.033a4.286 4.286 0 01-3.74-4.243zm7.43 2.888a1.066 1.066 0 00-1.016-.749h-.535v-1.605a.537.537 0 00-.535-.535h-3.21v-1.07h1.07c.295 0 .535-.24.535-.535v-1.07h1.07c.589 0 1.07-.481 1.07-1.07v-.219a4.281 4.281 0 012.68 3.964c0 1.113-.433 2.13-1.128 2.89zM12 2.501c-2.89 0-5.4 1.64-6.65 4.04A5.995 5.995 0 000 12.5c0 3.31 2.69 6 6 6h3.65a5.34 5.34 0 01-.193-2H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95A5.468 5.468 0 0112 4.502c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11A2.98 2.98 0 0122 13.5a3.004 3.004 0 01-1.914 2.79 5.341 5.341 0 01-.143 2.111A4.995 4.995 0 0024 13.5c0-2.64-2.05-4.78-4.65-4.96A7.489 7.489 0 0012 2.5z"
      ></path>
    </svg>
  );
}

export default Icon;
